<template>
  <div class="wytg">
    <!-- 详情页 -->
    <ShowDetail
      v-if="showDetail"
      @closeDialog2="closeDialog2"
      ref="showDetail"
    />
    <!-- 推送页 -->
    <JgDialog v-if="showTS" ref="jgDialog" @closeDialog="closeDialog" />
    <div class="form-list">
      <div class="table-search">
        <h3>接管推送</h3>
        <!-- 方便找页面 后期可能删除 -->
        <FormQuery
          ref="formquery"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
        >
        </FormQuery>
      </div>
      <div class="table-content">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.pnum"
          :pageSize="query.psize"
          :total="total"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        >
          <template slot="serviceType" slot-scope="scope">
            <span>{{
              scope.row.serviceType == 1 ? "全程托管" : "环节托管"
            }}</span>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button type="text" size="small" @click="detailClick(scope.row)"
              >查看</el-button
            >
            <el-button type="text" size="small" @click="push(scope.row)"
              >推送</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
<script>
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/table-page";
import JgDialog from "./JgDialog";
import ShowDetail from "./showDetail";
import { jgtsColumn } from "../tableColumn";
import { getCompanyListToPage, getXzqArr, getBreedInTrust } from "@/api/add";
import { removeEmptyChildren } from "@/js/utils/util";

const Query = [
  {
    name: "companyName",
    label: "名称:",
    componentType: "input",
    inputType: "",
    placeholder: "",
    width: "140px",
  },
  // 托管类型
  {
    name: "serviceType",
    label: "托管类型:",
    componentType: "select",
    placeholder: "请选择",
    width: "140px",
    dataOptions: [
      {
        name: "全程托管",
        value: 1,
      },
      {
        name: "环节托管",
        value: 2,
      },
    ],
  },
  //意向区域
  {
    name: "intentionRegionCode",
    label: "意向区域:",
    componentType: "cascader",
    placeholder: "请选择",
    width: "140px",
    props: {
      label: "areaName",
      children: "children",
      value: "id",
      checkStrictly: true,
    },
    dataOptions: [],
  },
  // 托管种养品种
  {
    name: "serviceScope",
    label: "托管种养品种:",
    inputType: "",
    componentType: "select",
    placeholder: "请选择",
    filterable: true,
    width: "140px",
    dataOptions: [],
  },
];
export default {
  components: {
    FormQuery,
    TablePage,
    JgDialog,
    ShowDetail,
  },
  filters: {
    name1Filter(value) {
      return value == 1 ? "全程托管" : "其它";
    },
  },
  data() {
    return {
      showDetail: false,
      orderObj: null,
      showTS: false,
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      form: {},
      Query: Query,
      query: {
        pnum: 1,
        psize: 10,
      },
      total: 0,
      tableData: [],
      columns: jgtsColumn,
      tableData: [],
      options: [
        { value: 0, label: "已成交" },
        { value: 1, label: "未成交" },
      ],
    };
  },
  created() {
    this.getRegions();
    this.getList();
    this.getBreedInTrust();
  },
  methods: {
    //关闭详情
    closeDialog2() {
      this.showDetail = false;
    },
    //点击详情
    detailClick(val) {
      this.showDetail = true;
      this.$nextTick(() => {
        this.$refs.showDetail.init(val);
      });
    },
    //获取行政区
    async getRegions() {
      let res = await getXzqArr({
        level: 3,
      });
      if (res.code == 0) {
        let hasRegion = setInterval(() => {
          this.Query[2].dataOptions = removeEmptyChildren(res.data, "children");
          console.log(this.Query[2].dataOptions);
          this.keyForm++;
          clearInterval(hasRegion);
        }, 100);
      }
    },

    //托管种养品种
    async getBreedInTrust() {
      let res = await getBreedInTrust();
      console.log(res, "数据格式");
      // this.Query[3].dataOptions = [];
      try {
        res.data.forEach((item, index) => {
          // console.log(item.categoryName)
          let abc = {};
          abc.name = item.categoryName;
          abc.value = item.categoryName;
          this.Query[3].dataOptions.push(abc);
          // console.log(this.Query[3].dataOptions, "这是第三条数据");
        });
      } catch (error) {
        console.log("打印错误");
      }
    },

    //关闭推送
    closeDialog() {
      this.showTS = false;
    },
    //当前页
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.getList();
    },
    //总页数改变
    handleSizeChange(size) {
      this.query.pnum = 1;
      this.query.psize = size;
      this.getList();
    },
    //根据条件获取列表
    async getList() {
      try {
        // let query2=  this.Query[3].dataOptions[this.query.serviceScope]
        // console.log(query2.name,'query2')
        let res = await getCompanyListToPage(this.query);
        console.log(this.query);
        console.log(res, "查看数据");
        // console.log(res,'根据条件获取列表')
        // console.log(this.query,'这是什么')
        if (!res.code) {
          this.tableData = res.data;
          this.total = res.dataNum;
        } else {
          this.tableData = [];
          this.$message.error("查询失败");
        }
      } catch (error) {}
    },

    //显示推送弹框
    push(b) {
      console.log("b", b);
      this.showTS = true;
      this.$nextTick(() => {
        this.$refs.jgDialog.getList(b);
      });
    },
    //条件点搜索
    search(params) {
      // this.query = { ...this.query, ...params,...{serviceScope:params.serviceScope.join(",")} };
      // params = {
      //   ...params,
      //   serviceScope: params.serviceScope || params.serviceScope === 0 ? String(params.serviceScope) : null
      // }
      console.log(params, "打印的params");
      this.query = {
        ...this.query,
        ...params,
        ...{
          intentionRegionCode: params.intentionRegionCode
            ? params.intentionRegionCode[params.intentionRegionCode.length - 1]
            : null,
        },
        ...{},
      };
      this.getList();
    },
    //重置
    reset(params) {
      this.query = {
        pnum: 1,
        psize: 10,
      };
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;

    /deep/.el-button {
      padding: 10px 20px !important;
    }

    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }

  .table-content {
    padding: 0 30px;
    margin-top: 6px;
  }
}
</style>
